import { BACKEND_URL } from '../constants'

import { createHeaders } from './createHeaders'

interface IErrorLog {
  message: string
  sourceVersion: string
  stacktrace: string[]
  userAgent: string
  actionHistory: string[] // history of dispatched redux actions before the error happened
  url: string
}

export function logError(errorLog: IErrorLog): Promise<any> {
  const url = `${BACKEND_URL}/api/logging`
  const options = {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(errorLog),
  }

  return fetch(url, options)
}
