import { APP_ERRORS } from '../../constants/errors'
import { onGlobalError } from '../../middlewares/errorLogger'

import type { IAppError } from '../interfaces/errors/IAppError'

// Some headers need sanitizing before logging them in errors
// e.g. `Authorization` contains access tokens that we don't want to log
const sanitizeRequestInit = (req: RequestInit): RequestInit => {
  const headers = new Headers(req.headers)

  // Remove access token header
  headers.delete('authorization')

  // Remove custom HTTP header
  headers.delete('x-requested-with')

  // Convert Headers into key/value pair
  const headersObj: Record<string, string> = {}
  headers.forEach((val, key) => {
    headersObj[key] = val
  })

  return { ...req, headers: headersObj }
}

/**
 * Parse the response as JSON. Throws an error when the response status
 * is not in the range >=200 and < 300
 * @param response
 * @return {Promise.<*, Error>}
 */
export async function handleJSONResponse(init: RequestInit, response: Response): Promise<any> {
  if (response.status === 401) {
    const errorToShow: IAppError = {
      type: 'login-expired',
      message: APP_ERRORS.LOGIN_EXPIRED,
      timeout: 10000,
      response,
    }
    return Promise.reject(errorToShow)
  }

  const phrase = statusCodesToPhrases[response.status] || ''

  const responseContentAsText = await response.text()

  function reportError(error: string) {
    const errorToLog = {
      reference: 'handleJSONResponse',
      error,
      request: sanitizeRequestInit(init),
      status: response.status,
      phrase,
      url: response.url,
      response: responseContentAsText,
    }
    onGlobalError(errorToLog)

    // Show custom HTTP error in snackbar
    const errorToShow: IAppError = {
      type: 'request',
      message: responseContentAsText,
      timeout: 10000,
      response,
    }
    return Promise.reject(errorToShow)
  }

  // handle empty responses
  if (response.status === 204) {
    return Promise.resolve(null)
  }

  if (response.status < 200 || response.status >= 300) {
    // this kind of errors shouldn't exist
    // log them so we can look into these kind of situations
    return reportError(`Response code ${response.status} received`)
  }

  try {
    return JSON.parse(responseContentAsText)
  } catch (e) {
    return reportError('Failed to parse JSON')
  }
}

const statusCodesToPhrases: any = {
  '100': 'Continue',
  '101': 'Switching Protocols',
  '102': 'Processing',
  '200': 'OK',
  '201': 'Created',
  '202': 'Accepted',
  '203': 'Non-Authoritative Information',
  '204': 'No Content',
  '205': 'Reset Content',
  '206': 'Partial Content',
  '207': 'Multi-Status',
  '226': 'IM Used',
  '300': 'Multiple Choices',
  '301': 'Moved Permanently',
  '302': 'Found',
  '303': 'See Other',
  '304': 'Not Modified',
  '305': 'Use Proxy',
  '307': 'Temporary Redirect',
  '308': 'Permanent Redirect',
  '400': 'Bad Request',
  '401': 'Unauthorized',
  '402': 'Payment Required',
  '403': 'Forbidden',
  '404': 'Not Found',
  '405': 'Method Not Allowed',
  '406': 'Not Acceptable',
  '407': 'Proxy Authentication Required',
  '408': 'Request Timeout',
  '409': 'Conflict',
  '410': 'Gone',
  '411': 'Length Required',
  '412': 'Precondition Failed',
  '413': 'Payload Too Large',
  '414': 'URI Too Long',
  '415': 'Unsupported Media Type',
  '416': 'Range Not Satisfiable',
  '417': 'Expectation Failed',
  '418': "I'm a teapot",
  '422': 'Unprocessable Entity',
  '423': 'Locked',
  '424': 'Failed Dependency',
  '426': 'Upgrade Required',
  '428': 'Precondition Required',
  '429': 'Too Many Requests',
  '431': 'Request Header Fields Too Large',
  '451': 'Unavailable For Legal Reasons',
  '500': 'Internal Server Error',
  '501': 'Not Implemented',
  '502': 'Bad Gateway',
  '503': 'Service Unavailable',
  '504': 'Gateway Time-out',
  '505': 'HTTP Version Not Supported',
  '506': 'Variant Also Negotiates',
  '507': 'Insufficient Storage',
  '511': 'Network Authentication Required',
}
