import { identity, always } from './functions'
import { toRecord } from './obj'
import { Predicate, notFalse } from './predicates'

export type None = false
export const none: None = false

type NotNone<A> = A extends None ? never : A

export type WithPropertiesNotNone<O extends Record<string, unknown>, K extends keyof O> = {
  [KK in Exclude<keyof O, K>]: O[KK]
} & { [KK in K]: NotNone<O[KK]> }

export const StrictNull = {
  notNone: notFalse,
  fromUndefined<A>(a: A | undefined): A | None {
    return a === undefined ? none : a
  },
  toUndefined<A, B>(a: A | None, fn: (a: A) => B): B | undefined {
    return StrictNull.fold(a, fn, undefined)
  },
  map<A, B>(a: A | None, fn: (a: A) => B): B | None {
    if (a === none) {
      return none
    }

    return fn(a)
  },
  mapFromUndefined<A, B>(a: A | undefined, fn: (a: A) => B): B | None {
    return StrictNull.map(StrictNull.fromUndefined(a), fn)
  },
  mapMany<Params extends any[], Result>(
    fn: (...params: Params) => Result,
    ...optionalParams: { [K in keyof Params]: Params[K] | None }
  ): Result | None {
    const hasNone = optionalParams.some(p => p === none)
    if (hasNone) {
      return none
    }
    const params = optionalParams as Params
    return fn(...params)
  },
  flatMap<A, B>(a: A | None, fn: (a: A) => B | None): B | None {
    if (a === none) {
      return none
    }

    return fn(a)
  },
  orElse<A, B>(a: A | None, alternative: B): A | B {
    return a === none ? alternative : a
  },
  orEmpty<A>(a: A | None): A | never[] {
    const empty: never[] = []
    return StrictNull.orElse(a, empty)
  },
  lift<Parameters extends any[], Result>(
    fn: (...ps: Parameters) => Result
  ): (...ps: { [K in keyof Parameters]: Parameters[K] | None }) => Result | None {
    return (...noneablePs: { [K in keyof Parameters]: Parameters[K] | None }): Result | None => {
      const hasNone = noneablePs.some(p => p === none)
      if (hasNone) {
        return none
      }
      const ps = noneablePs as Parameters
      return fn(...ps)
    }
  },
  toArray<A>(a: A | None): A[] {
    return a === none ? [] : [a]
  },
  filter<A>(a: A, predicate: Predicate<A>): A | None {
    return predicate(a) ? a : none
  },
  fold<A, B, Alternative>(a: A | None, fn: (a: A) => B, alternative: Alternative): B | Alternative {
    return StrictNull.orElse(StrictNull.map(a, fn), alternative)
  },
  hasProperties:
    <O extends Record<string, unknown>, K extends keyof O>(...ks: K[]) =>
    (o: O): o is O & WithPropertiesNotNone<O, K> => {
      return !ks.some(k => (o[k] as O[keyof O] | None) === none)
    },
  find: <A>(a: Array<Exclude<A, None>> | None, fn: (a: A) => boolean): A | None => {
    return StrictNull.map(a, arr => StrictNull.fromUndefined(arr.find(fn)))
  },
  isNotNull<A>(a: A | null): a is A {
    return a !== null
  },
  assertNotNone<A>(a: A | None, message = `Expected ${a} to not be \`None\`.`): A {
    if (a === none) {
      throw new Error(message)
    } else {
      return a
    }
  },
  sequence<A extends any[]>(...arr: { [K in keyof A]: A[K] }): { [K in keyof A]: NotNone<A[K]> } | None {
    if (arr.some(a => a === none)) {
      return none
    }
    return arr as { [K in keyof A]: NotNone<A[K]> }
  },
  toNoneRecord<Keys extends string | number | symbol>(...keys: Keys[]): Record<Keys, None> {
    return toRecord(keys, identity, always(none))
  },
}

type NotUndefined<A> = A extends undefined ? never : A

type WithPropertiesNotUndefined<O extends unknown, K extends keyof O> = { [KK in Exclude<keyof O, K>]: O[KK] } & {
  [KK in K]-?: NotUndefined<O[KK]>
}

export const StrictUndefined = {
  map<A, B>(a: A | undefined, fn: (a: A) => B): B | undefined {
    if (a === undefined) {
      return undefined
    }

    return fn(a)
  },
  orElse<A, B>(a: A | undefined, alternative: B): A | B {
    return a === undefined ? alternative : a
  },
  fold<A, B, Alternative>(a: A | undefined, fn: (a: A) => B, alternative: Alternative): B | Alternative {
    return StrictUndefined.orElse(StrictUndefined.map(a, fn), alternative)
  },
  isNotUndefined<A>(a: A | undefined): a is A {
    return a !== undefined
  },
  isUndefined<A>(a: A | undefined): a is A {
    return a === undefined
  },
  lift<Parameters extends any[], Result>(
    fn: (...ps: Parameters) => Result
  ): (...ps: { [K in keyof Parameters]: Parameters[K] | undefined }) => Result | undefined {
    return (...undefineablePs: { [K in keyof Parameters]: Parameters[K] | undefined }): Result | undefined => {
      const hasUndefined = undefineablePs.some(p => p === undefined)
      if (hasUndefined) {
        return undefined
      }
      const ps = undefineablePs as Parameters
      return fn(...ps)
    }
  },
  hasProperties<O extends Record<string, unknown>, K extends keyof O>(
    o: O,
    ...ks: K[]
  ): o is O & WithPropertiesNotUndefined<O, K> {
    return !ks.some(k => o[k] === undefined)
  },
  ensurePropertiesDefined<O extends unknown, K extends keyof O>(
    o: O,
    ...ks: K[]
  ): WithPropertiesNotUndefined<O, K> | None {
    if (ks.some(k => o[k] === undefined)) {
      return none
    }
    return o as WithPropertiesNotUndefined<O, K>
  },
  assertNotUndefined<A>(a: A | undefined, message = `Expected ${a} to not be undefined.`): A {
    if (a === undefined) {
      throw new Error(message)
    } else {
      return a
    }
  },
}
