import { BehaviorSubject, lastValueFrom, noop } from 'rxjs'
import { filter, mergeMap, take } from 'rxjs/operators'

type State = {
  isAuthenticated: boolean
  getTokenSilently: () => Promise<string>
}

// Create uninitialised Auth0 client observable
export const authClientSubject = new BehaviorSubject<State>({
  isAuthenticated: false,
  getTokenSilently: () => new Promise(noop),
})

// Extract auth token from Auth0 client
export const getAuthToken = () =>
  lastValueFrom(
    authClientSubject
      .pipe(
        filter(({ isAuthenticated }) => isAuthenticated),
        mergeMap(({ getTokenSilently }) => getTokenSilently())
      )
      .pipe(take(1))
  )
