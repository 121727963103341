export enum TestIds {
  HeaderPlanningButton = 'header-button-planning',
  HeaderMapButton = 'header-button-map',
  HeaderDashboardButton = 'header-button-dashboard',
  PendingLane = 'pending-lane',
  PendingLaneHeader = 'pending-lane-header',
  TerminalPlanningShip = 'terminal-planning-ship',
  TerminalPlanningJettyShip = 'terminal-planning-ship',
  ServicesTimeline = 'services-timeline',
  ServicesTimelineItem = 'services-timeline-item',
  MapShipMarkerName = 'ship-marker-name',
  MapLocationUnavailable = 'map-location-unavailable',
  MapShipPopupWrapper = 'ship-popup-wrapper',
  MapShipPopupTable = 'ship-popup-table',
  Ticker = 'ticker',
  FilterOptionAgents = 'filter-option-agents',
  FilterOptionBerths = 'filter-option-berths',
  FilterOptionCarriers = 'filter-option-carriers',
  FilterOptionPorts = 'filter-option-ports',
  FilterOptionTerminals = 'filter-option-terminals',
  PortcallsTable = 'portcalls-table',
  PortcallsTableRow = 'portcalls-table-row',
  PortcallsTableVesselName = 'portcalls-vessel-name',
  PortcallsTableBerthName = 'portcalls-berth-name',
  CookieBar = 'cookie-bar',
  RegistrationButton = 'registration-button',
  LoginButton = 'login-button',
  LogoutButton = 'logout-button',
  MenuNotificationsButton = 'menu-notifications-button',
  MenuNotificationsPopup = 'menu-notifications-popup',
  HamburgerMenuButton = 'hamburger-menu-button',
  SettingsNotification = 'notification-configuration',
  SettingsNotificationShowInProductToggle = 'show-in-product',
  NotificationListItem = 'notification-list-item',
  UserManagementNewUserButton = 'user-management-new-user-button',
  UserManagementModalFormNameInput = 'user-management-modal-form-name-input',
  UserManagementModalFormSurNameInput = 'user-management-modal-form-surname-input',
  UserManagementModalFormEmailInput = 'user-management-modal-form-email-input',
  UserManagementModalFormGroupsSelect = 'user-management-modal-form-groups-select',
  UserManagementModalFormRolesSelect = 'user-management-modal-form-roles-select',
  UserManagementModalFormPortsSelect = 'user-management-modal-form-ports-select',
  UserManagementModalFormLanguageSelect = 'user-management-modal-form-language-select',
  UserManagementModalFormMeasurementSystemSelect = 'user-management-modal-form-measurement-system-select',
  UserManagementModalFormSubmitButton = 'user-management-modal-form-submit-button',
  UserManagementModalFormDeleteButton = 'user-management-modal-form-delete-button',
  UserManagementModalFormConfirmDeletionButton = 'user-management-modal-form-confirm-deletion-button',
  TableHeaderRow = 'table-header-row',
  TableBodyRow = 'table-body-row',
  OnboardingContinueButton = 'onboarding-continue-button',
  GenericDropdown = 'generic-dropdown',
  Loader = 'loader',
  JitMonitorVesselButton = 'jit-monitor-vessel-button',
  JustInTimeArrivalSpeedProfilePath = 'just-in-time-arrival-speed-profile-path',
  JustInTimeArrivalOptimalSpeedPath = 'just-in-time-arrival-optimal-speed-path',
  VesselSpeedGraphTooltipFormattedTimeRow = 'vessel-speed-graph-tooltip-formatted-time-row',
  VesselSpeedGraphTooltipOptimalSpeedRow = 'vessel-speed-graph-tooltip-optimal-speed-row',
  VesselSpeedGraphSpeedInKnotsRow = 'vessel-speed-graph-tooltip-speed-in-knots-row',
  VesselSpeedGraphTooltipRestrictedAreaNameRow = 'vessel-speed-graph-tooltip-restricted-area-name-row',
  VesselSpeedGraphTooltipMaxSpeedRow = 'vessel-speed-graph-tooltip-max-speed-row',
  VesselSpeedGraphTooltipTypeUpdatedArrivalTime = 'vessel-speed-graph-tooltip-type-updated-arrival-time',
  VesselSpeedGraphTooltipTypeOptimalSpeed = 'vessel-speed-graph-tooltip-type-optimal-speed',
  ApiKeysTable = 'api-keys-table',
  ApiKeysTableRow = 'api-keys-table-row',
  ApiKeyOptionsButton = 'api-key-options-button',
  RevokeApiKeyButton = 'revoke-api-key-button',
  RemoveApiKeyButton = 'remove-api-key-button',
  CopyPasteFieldContainer = 'copy-paste-field-container',
  CopyPasteFieldLabel = 'copy-paste-field-label',
  CopyPasteFieldTextArea = 'copy-paste-field-text-area',
  CopyPasteFieldCopyButton = 'copy-paste-field-copy-button',
  SnackbarHeader = 'snackbar-header',
}
